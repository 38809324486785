var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "df m-r-16 m-l-16",
      staticStyle: { "flex-direction": "column", height: "100%" },
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "mobile-login_form-container" },
        [
          _c("div", { staticClass: "modal-switcher" }, [
            _c(
              "span",
              {
                staticClass: "switcher-item",
                class: { active: _vm.checked },
                on: { click: _vm.enter },
              },
              [_vm._v(" Вход ")]
            ),
            _c(
              "span",
              {
                staticClass: "switcher-item",
                class: { active: !_vm.checked },
                on: { click: _vm.registration },
              },
              [_vm._v(" Регистрация ")]
            ),
          ]),
          _c(
            "keep-alive",
            [
              _c(_vm.contentComponent, {
                tag: "component",
                on: {
                  close: function ($event) {
                    return _vm.$emit("close")
                  },
                  "code-check": function ($event) {
                    return _vm.$emit("show-CheckDialog", $event)
                  },
                  "show-AgreeDialog": function ($event) {
                    return _vm.$emit("showAgreeDialog")
                  },
                  "forgotten-password": function ($event) {
                    return _vm.$emit("forgotten-password")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "df ai-c jc-sb", staticStyle: { height: "25%" } },
        [
          _c(
            "div",
            {
              staticClass: "df ai-bl",
              staticStyle: { "flex-direction": "column" },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "text-btn m-b-12",
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("showAgreeDialog")
                    },
                  },
                },
                [
                  _c("span", { staticClass: "mobile-login_footer-link" }, [
                    _vm._v(" Пользовательское соглашение "),
                  ]),
                ]
              ),
              _c(
                "el-button",
                {
                  staticClass: "text-btn m-b-12",
                  staticStyle: { "touch-action": "manipulation" },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("showFaqDialog")
                    },
                  },
                },
                [
                  _c("span", { staticClass: "mobile-login_footer-link" }, [
                    _vm._v(" Использование программы "),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { width: "18%", "max-width": "100px" } }, [
            _c(
              "a",
              {
                staticClass: "ts-none__card",
                attrs: {
                  href: "https://navigator.sk.ru/orn/1124520",
                  target: "_blank",
                },
              },
              [
                _c("img", {
                  staticClass: "ts-none__img",
                  attrs: {
                    src: require("@/assets/img/logo_skolkovo-white.svg"),
                    alt: "logo_skolkovo",
                  },
                }),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "df ai-fe jc-c",
        staticStyle: { height: "25%", "padding-bottom": "10%" },
      },
      [
        _c("img", {
          staticStyle: { width: "65%" },
          attrs: { src: require("../../../assets/img/logo-white.svg") },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }