<template>
  <div class="df m-r-16 m-l-16" style="flex-direction: column; height: 100%">
    <!--      Logo-->
    <div class="df ai-fe jc-c" style="height: 25%; padding-bottom: 10%">
      <img style="width: 65%" src="../../../assets/img/logo-white.svg" />
    </div>

    <!--      Form-->
    <div class="mobile-login_form-container">
      <!-- switcher -->
      <div class="modal-switcher">
        <span class="switcher-item" :class="{ active: checked }" @click="enter">
          Вход
        </span>

        <span
          class="switcher-item"
          :class="{ active: !checked }"
          @click="registration"
        >
          Регистрация
        </span>
      </div>
      <keep-alive>
        <component
          :is="contentComponent"
          @close="$emit('close')"
          @code-check="$emit('show-CheckDialog', $event)"
          @show-AgreeDialog="$emit('showAgreeDialog')"
          @forgotten-password="$emit('forgotten-password')"
        />
      </keep-alive>
    </div>
    <!--      Footer-->
    <div class="df ai-c jc-sb" style="height: 25%">
      <div class="df ai-bl" style="flex-direction: column">
        <el-button
          class="text-btn m-b-12"
          type="text"
          @click="$emit('showAgreeDialog')"
        >
          <span class="mobile-login_footer-link">
            Пользовательское соглашение
          </span>
        </el-button>
        <el-button
          class="text-btn m-b-12"
          type="text"
          style="touch-action: manipulation"
          @click="$emit('showFaqDialog')"
        >
          <span class="mobile-login_footer-link">
            Использование программы
          </span>
        </el-button>
      </div>

      <div style="width: 18%; max-width: 100px">
        <a
          href="https://navigator.sk.ru/orn/1124520"
          target="_blank"
          class="ts-none__card"
        >
          <img
            :src="require('@/assets/img/logo_skolkovo-white.svg')"
            alt="logo_skolkovo"
            class="ts-none__img"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import EntranceForm from '@/views/login/components/EntranceFormMobile'
import RegistrationForm from '@/views/login/components/RegistrationFormMobile'

export default {
  name: 'Login',
  components: {
    EntranceForm,
    RegistrationForm,
  },
  data() {
    return {
      checked: true,
      contentComponent: 'EntranceForm',
    }
  },
  methods: {
    enter() {
      this.contentComponent = 'EntranceForm'
      this.checked = true
    },
    registration() {
      this.contentComponent = 'RegistrationForm'
      this.checked = false
    },
  },
}
</script>

<style lang="sass">
.mobile-login_title
  font-family: Noto-Sans, sans-serif
  font-style: normal
  font-weight: 500
  font-size: 22px
  line-height: 30px
  color: #303133
.mobile-login_form-title
  font-family: 'Roboto'
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 22px
  display: flex
  align-items: center
  color: #606266
.mobile-login_form-btn
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 22px
  color: #ffffff
.mobile-login_form-container
  background: #ffffff
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08)
  border-radius: 16px
  padding: 20px 20px
.mobile-login_footer-link
  color: #c0c4cc!important
  font-weight: 400!important
  font-size: 12px!important
  line-height: 18px
.text-btn.el-button--text span
  border-bottom: 1px solid #C0C4CC!important
.ai-bl
  align-items: baseline
</style>
